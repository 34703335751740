<template>
  <ul class="menus">
    <li v-for="(item,index) in menus">
      <p style="cursor:pointer" @click="SlidingJump(item)">{{index+1}}.{{item.title}}</p>
      <ul v-if="item[childLabel]" class="child">
        <menu-tree :menus="item[childLabel]" :child-label="childLabel"></menu-tree>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: "menuTree",
  props: {
    menus: {
      type: [Object, Array],
      required: true
    },
    childLabel: {
      type: String,
      default: "child"
    }
  },
  data() {
    return {};
  },
  methods: {
    SlidingJump(item) {
      this.$nextTick(() => {
        var x = item.offsetTop / 5;

        debugger

        for (let index = 0; index < x; index++) {
          if (document.documentElement.scrollTop > item.offsetTop) {
            if (document.documentElement.scrollTop <= item.offsetTop) {
              break;
            }
            document.documentElement.scrollTop =
              document.documentElement.scrollTop -  x ;
          } else {
               if (document.documentElement.scrollTop >= item.offsetTop) {
              break;
            }
            document.documentElement.scrollTop =
              document.documentElement.scrollTop +  x ;
          }
        }

        // document.documentElement.scrollTop = item.offsetTop - 100;
      });
    }
  }
};
</script>

<style scoped lang="less">
.menus {
  line-height: 1.7em;
}
.child {
  padding-left: 30px;
}
</style>
