<template>
  <div>
    <div class="main-input">
      <div class="ttp-avatar auth-none">
        <img
          src="https://sf3-cdn-tos.toutiaostatic.com/img/mosaic-legacy/3795/3033762272~c5_300x300.image"
          alt="头像"
        />
      </div>

      <div class="ttp-comment-input">
        <textarea
          v-model="textarea"
          class="comment-textarea"
          placeholder="说点什么吧..."
        >
        </textarea>

        <div class="action">
          <button
            @click="submitReply"
            :class="{ 'submit-btn': true, disable: Isdisable }"
          >
            评论
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    Isdisable: false;
  },

  props: ["Text"],

  watch: {
    Text: {
      deep: true,
      handler: function(newV, oldV) {
        this.$nextTick(ic => {
          this.Isdisable = newV == "" ? true : false;
        });
      }
    }
  }
};
</script>


<style scoped>

</style>