<template>
  <div style="width: 90%; margin: auto">
    <witMsg 
      ref="initwitmsg"
      :istitle="true"
      @submitReply="submitReply"
      :messagecount="messagecount"
    ></witMsg>
    <el-divider content-position="left"
      ><i
        style="font-size: 20px"
        @click="Indexmsg"
        class="el-icon-refresh"
      ></i
    ></el-divider>
    <Msg
      v-loading="loading"
      :comments="comments"
      :isdow="isdow"
      :more="more"
      :pagint="pagint"
      :messagecount="messagecount"
      @drawer="drawerclick"
    ></Msg>
  </div>
</template>

<script>
import witMsg from "@/components/wtMsg";

import Msg from "@/components/Msg";

import { PostBlogMessage } from "../api/Resources";

export default {
  components: {
    Msg,
    witMsg,
  },
  props: ["BId", "messagecount"],
  data() {
    return {
      loading: false,
      isdow: false /**是否显示 */,
      pagint: true /** 是否分页 */,
      more: false /** 是否加载更多 */,
      MsgBlogPageDto: {
        BlogId: "",
        PageIndex: 1,
        PageSize: 5,
        ParentId: 0,
      },
      comments: [],
    };
  },

  methods: {
    Indexmsg() {
      this.comments=[];
      this.MsgBlogPageDto.PageIndex = 1;
      this.BlogMessage();
    },
    BlogMessage() {
      this.loading = true;
      this.MsgBlogPageDto.BlogId = this.BId;
      PostBlogMessage(this.MsgBlogPageDto).then((res) => {
        this.comments = this.comments.concat(res.rspdata.data.data);
        this.loading = false;
        if (this.MsgBlogPageDto.PageIndex == 1) {
          this.$notify({
            title: "评论",
            message: "共计" + this.messagecount + "条评论",
            type: "success",
            duration:2000
          });
        }
        if (
          res.rspdata.data.pageindex * res.rspdata.data.pagesize >
          res.rspdata.data.total
        ) {
          this.pagint = false;
        }
      });
    },

    submitReply(textareatest) {},
    drawerclick() {
      this.MsgBlogPageDto.PageIndex += 1;
      this.BlogMessage();
    },
    Loadmore() {},
  },

  created() {
    this.BlogMessage();
  },
};
</script>